import store from './store';
import * as config from './config.slice'

export const getConfig =  store.getState('config');

export const openPIN = () => {
  store.dispatch(config.setVisiblePIN(true));
}

export const closePIN = () => {
  store.dispatch(config.setVisiblePIN(false));
}

export const openAddCard = () => 
  store.dispatch(config.setVisibleAddCard(true));

export const closeAddCard = () => 
  store.dispatch(config.setVisibleAddCard(false));

export const enableEditableDashboard = () => 
  store.dispatch(config.setEditableDashboard(true));

export const disableEditableDashboard = () => 
  store.dispatch(config.setEditableDashboard(false));


