import { Responsive, WidthProvider } from "react-grid-layout";
const ResponsiveGridLayout = WidthProvider(Responsive);

const layout = [
  { i: "item-1", x: 0, y: 0, w: 1, h: 1 },
  { i: "item-2", x: 1, y: 0, w: 1, h: 1 },
  { i: "item-3", x: 2, y: 0, w: 1, h: 1 },
  { i: "item-4", x: 3, y: 0, w: 1, h: 1 },

];

const getLayouts = () => {
  const savedLayouts = localStorage.getItem("grid-layout");
  return savedLayouts ? JSON.parse(savedLayouts) : { lg: layout };
};

export const Dashboard = ({ children, editable = false }) => {

  const handleLayoutChange = (layout, layouts) => {
    //another process/save button should
    //copy the temp-grid-layout to grid-layout
    //when save is pressed
    localStorage.setItem("temp-grid-layout", JSON.stringify(layouts));
  };

  return (
      <ResponsiveGridLayout
        layouts={getLayouts()}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 8, sm: 6, xs: 4, xxs: 3 }}
        rowHeight={30}
        width={100}
        isDraggable={editable}
        isResizable={editable}
        resizable={editable}
        onLayoutChange={handleLayoutChange}
      >
        {children}        
      </ResponsiveGridLayout>
  );
};