import { FaCog, FaSave } from "react-icons/fa";
import { useSelector } from "react-redux";
import { disableEditableDashboard, openPIN } from "../store/config.dispatch";

export const Cog = ({ size = "18" }) => {
  const editableDashboard = useSelector(state => state.config.editableDashboard);

  return (
    <div
      className="
        fixed
        z-10
        bottom-0
        right-0
        mr-5
        mb-5
      "
    >
      {!editableDashboard &&
        <button onClick={openPIN}>
          <FaCog className="fill-orange-500" size={size} />
        </button>}
      {editableDashboard &&
        <button onClick={() => {
          disableEditableDashboard();
          localStorage['grid-layout'] = localStorage['temp-grid-layout'];
        }}>
          <FaSave className="fill-slate-300" size={size} />
        </button>}
    </div>
  )
}