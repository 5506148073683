// https://github.com/codiini/react-audio-video-recorder/blob/main/src/AudioRecorder.jsx
import { useState, useRef, useEffect } from "react";
import { getValue } from "../db/db";

export const AudioCard = ({ cardId }) => {

  const [audio, setAudio] = useState(null);
  const [hasRecording, setHasRecording] = useState(false);
  const [playerId, setPlayerId] = useState(null);

  useEffect(() => {  
    setPlayerId(`player-${cardId}`);
  }, [cardId])

  const loadAudioUsingCardId = async ({ cardId }) => {
    const audioBlob = await getValue({ key: cardId });
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setHasRecording(true)
    }
  }

  useEffect(() => {
    loadAudioUsingCardId({ cardId });
  }, [cardId])

  const stopAudio = () => {
    document.getElementById(playerId).pause();
  }
  const playAudio = () => {
    document.getElementById(playerId).currentTime = 0;
    document.getElementById(playerId).play();
  }

  return (
    (audio && playerId) ? (
      <div
        className="audio-player bg-yellow-200 w-full h-full flex-1"
        onClick={playAudio}
      >
        <div>Hi {playerId}</div>
        <audio id={playerId} src={audio}></audio>
      </div>
    ) : null
  );
};