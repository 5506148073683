import { useEffect, useState } from "react";
import { VoiceRecorder } from "./VoiceRecorder";
import { closeAddCard, closePIN } from "../store/config.dispatch";
import { useSelector } from "react-redux";

export const CreateCard = () => {
  const isVisible = useSelector(state => state.config.visibleAddCard);
  const [cardId, setCardId] = useState(null);

  useEffect(() => {
    setCardId('cardId-'+Math.random())
  }, [isVisible]);

  return isVisible ? (
    <div
      className="
        fixed
        z-10
        top-0
        left-0
        w-full
        h-screen
        flex
        flex-col
        justify-center
        items-center
        bg-slate-400"
    >
      <div
        className="
          w-11/12
          md:w-6/12
          lg:w-6/12
          xl:w-4/12
          w-
          m-2
          p-8
        bg-slate-300
          rounded-lg
        "
      >
        <div className="flex flex-row space-x-2 items-center">
          <div className="rounded-full p-3 bg-green-600">01</div>
          <span>Take a picture of the item</span>
        </div>
        

        <div className="flex flex-row space-x-2 items-center">
          <div className="rounded-full p-3 bg-green-600">02</div>
          <span>Record your voice</span>
        </div>
        <VoiceRecorder
          cardId={cardId}
          isVisible={true}
        />
        <div className="flex space-x-2 mt-4">
          <button className="py-3 px-4 bg-green-500 rounded-md" onClick={closeAddCard}>
            Save
          </button>
          <button
            className="py-3 px-4 bg-orange-500 rounded-md"
            onClick={closeAddCard}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
