import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getItems } from './db/db';

import { Dashboard } from './Dashboard';
import { CreateCard } from './components/CreateCard';
import { AudioCard } from './components/AudioCard';
import { MyPinField } from './components/MyPINField';
import { disableEditableDashboard, openAddCard, openPIN } from './store/config.dispatch';

import './App.css';
import { FaCog, FaSave } from 'react-icons/fa';
import { Cog } from './components/Cog';

const DummyDashBoard = ({ components }) => {
  const editDashboard = useSelector(state => state.config.editableDashboard);
  return (
    <Dashboard editable={editDashboard}>
      {components.map((component, idx) =>
        <div
          key={'item-' + idx}
          i={'item-' + idx}
          className="bg-purple-100 flex flex-col justify-center items-center overflow-hidden cursor-pointer"
        >
          {component}
        </div>
      )}
    </Dashboard>
  );
}

function App() {
  const [arrayOfCards, setArrayOfCards] = useState([]);
  const getAllKeys = async () => {
    const itemArray = [];
    const items = await getItems();

    for await (const item of items) {
      itemArray.push(<AudioCard cardId={item} />)
    }
    setArrayOfCards(itemArray);
  }

  useEffect(() => {
    getAllKeys();
  }, []);

  return (
    <div className="App">
      <div className="flex p-2 bg-slate-500 justify-between">
        <button onClick={openAddCard}>Create Card</button>
        <Cog size={64}/>
      </div>
      <CreateCard />
      <MyPinField />

      {/* 
        never ever remove the arrayOfCards.length > 0
        other wise it will pass an empty array
        and remove all dashboard settings
      */}
      {
        arrayOfCards.length > 0 
        ? <DummyDashBoard components={arrayOfCards} />
        : null
      }

    </div>
  );
}

export default App;
