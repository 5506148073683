import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  visibleAddCard: false,
  visiblePIN: false,
  editableDashboard: false,
}

const configSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setVisibleAddCard(state,action) {
      state.visibleAddCard = action.payload;
    },
    setVisiblePIN(state,action) {
      state.visiblePIN = action.payload;
    },
    setEditableDashboard(state,action) {
      state.editableDashboard = action.payload;
    }
  },
})

export const {
  setVisiblePIN,
  setVisibleAddCard,
  setEditableDashboard,
} = configSlice.actions

export default configSlice.reducer