import PinField from "react-pin-field";
import { useSelector } from "react-redux";
import { closePIN, enableEditableDashboard } from "../store/config.dispatch";
import { useEffect } from "react";

export const MyPinField = () => {
  const isVisible = useSelector(state => state.config.visiblePIN);

  return isVisible ? (
    <div
      className="
        fixed
        z-10
        top-0
        left-0
        w-full
        h-screen
        flex
        flex-col
        justify-center
        items-center
        bg-slate-500
        bg-opacity-90
        "
    >
      <div
        className="
          w-11/12
          md:w-6/12
          lg:w-6/12
          xl:w-4/12
          w-
          m-2
          p-8
        bg-slate-200
          rounded-lg
        "
      >
        <PinField
          length={3}
          
        />
        <div className="flex space-x-2 mt-4">
          <button className="py-3 px-4 bg-green-500 rounded-md" onClick={() => {
            //unlock code goes here
            enableEditableDashboard();
            closePIN();
          }}>
            Unlock
          </button>
          <button
            className="py-3 px-4 bg-orange-500 rounded-md"
            onClick={closePIN}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
