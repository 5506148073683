// https://github.com/codiini/react-audio-video-recorder/blob/main/src/AudioRecorder.jsx
import { useState, useRef, useEffect } from "react";
import { FaMicrophone, FaPlay, FaAngleLeft, FaCheck, FaPause } from 'react-icons/fa';
import { setValue, getValue } from "../db/db";
const mimeType = "audio/webm";

export const VoiceRecorder = ({ cardId }) => {
  const [permission, setPermission] = useState(false);

  const mediaRecorder = useRef(null);

  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [hasRecording, setHasRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);

  const loadAudio = async ({ cardId }) => {
    const audioBlob = await getValue({ key: cardId });
    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setHasRecording(true)
    }
  }
  useEffect(() => {
    loadAudio({ cardId })
  }, [cardId])
  
  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(mediaStream);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("We are so, so sorry, but the MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setRecordingStatus("recording");
    setHasRecording(false);
    const media = new MediaRecorder(stream, { type: mimeType });

    mediaRecorder.current = media;

    mediaRecorder.current.start();

    let localAudioChunks = [];

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };

    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    setHasRecording(true);
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = () => {
      console.log(audioChunks)
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudio(audioUrl);
      setValue({ key: cardId, value: audioBlob })
      setAudioChunks([]);
    };
  };

  const getStatus = () => permission && recordingStatus;
  const isRecording = () => getStatus() === 'recording';
  const isInactive = () => getStatus() === 'inactive';

  const BtnGetMicrophonePermissions = ({ isVisible }) => (
    isVisible
      ? <button
        onClick={getMicrophonePermission}
        type="button"
        className="p-4 rounded-full text-amber-400 bg-amber-700"
      >
        <FaMicrophone />
      </button >
      : null
  )

  const BtnRecord = ({ isVisible, isRecording }) => (
    isVisible ?
      <button
        onClick={
          isRecording
            ? stopRecording
            : startRecording
        }
        type="button"
        className={
          isRecording
            ? "p-4 rounded-full bg-red-600 heartbeat"
            : "p-4 rounded-full bg-red-600"
        }
      >
        <FaMicrophone />
      </button>
      : null
  )
  const BtnPlay = ({ isVisible, hasRecording }) => {
    const baseClass = "p-4 rounded-full bg-green-600 ";
    const [isPlaying, setIsPlaying] = useState(false);
    const [className, setClassName] = useState(baseClass);

    return (
      isVisible
        ? <button
          type="button"
          className={className}
          onClick={() => {
            if (!hasRecording) return alert('you need to record something first');
            const elm = document.getElementById('player');

            if (isPlaying) {
              stopRecording();
              setClassName(baseClass);
              elm.pause();
              setIsPlaying(false);
            } else {
              setClassName(baseClass + ' heartbeat');
              elm.currentTime = 0;
              elm.loop = true;
              elm.play();
              setIsPlaying(true);
            }
          }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        : null
    )
  }
  useEffect(() => {
    console.log(isRecording(), isInactive(), permission)
  }, [isRecording, isInactive, permission])
  return (
    <div>
      <main>
        <div className="audio-controls flex justify-center flex-row space-x-2">
          <BtnGetMicrophonePermissions isVisible={!permission} />
          <BtnRecord
            isVisible={permission}
            isRecording={isRecording()}
          />
          <BtnPlay
            isVisible={permission}
            hasRecording={hasRecording}
          />
        </div>
        {audio ? (
          <div className="audio-player">
            <audio id="player" src={audio}></audio>
            {/* <a download href={audio}>
              Download Recording
            </a> */}
          </div>
        ) : null}
      </main>
    </div>
  );
};