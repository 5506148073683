import localforage from "localforage";

const db = localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'iCare'
})

export const getValue = ({ key }) => {
  try {
    return localforage.getItem(key);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

export const setValue = ({ key, value }) => localforage.setItem(key, value);

export const getItems = async () => {
  return new Promise( (resolve, reject) => {
    localforage.keys().then(keys => resolve(keys))
  })
}

export const getJSON = ({ key }) => JSON.parse(localforage.getItem(key))

export const setJSON = ({ key, value }) => localforage.setItem(key, JSON.stringify(value))